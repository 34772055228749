import Header from '../header/Header'
import Footer from '../footer/Footer'
import Connect from '../connect/Connect'

import './App.css';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Connect></Connect>
      <Footer></Footer>
    </div>
  );
}

export default App;
